import {reactive, UnwrapRef} from "vue";
import {Draw, IDraw} from "@/layouts/workspace/djhdb/cms/theme/drawer/draws/draw";

export interface IImageTemplate {
    image_template_id: string
    image_url: string
    template_image_url: string | null
    draws: IDraw[] | null;
}

export class ImageTemplate {

    readonly data: UnwrapRef<IImageTemplate>;

    constructor(it: IImageTemplate) {
        this.data = reactive(it)
    }

    clear(): void {
        this.data.draws = null
        this.data.image_template_id = ''
        this.data.template_image_url = null
        this.data.image_url = ''
    }

    edit(it: IImageTemplate): void {
        this.data.image_template_id = it.image_template_id
        this.data.draws = it.draws
        this.data.image_url = it.image_url
        this.data.template_image_url = it.template_image_url
    }

    addDrawText(): void {
        this.data.draws = !this.data.draws ? [] : this.data.draws
        this.data.draws.push(new Draw('text', {
            color: "#000000",
            font_size: 20,
            font_url: "",
            align: -1,
            length_max: 0,
            length_min: 0,
            text: "",
            letter_spacing: 0,
            stroke_width: 0,
            stroke_color: '#000000'
        }))
    }

    addUrlImage(): void {
        this.data.draws = !this.data.draws ? [] : this.data.draws
        this.data.draws.push(new Draw('url_image', {
            height: 0,
            image_url: "",
            radius: [0, 0, 0, 0],
            width: 0
        }))
    }

    addAppletQr(): void {
        this.data.draws = !this.data.draws ? [] : this.data.draws
        this.data.draws.push(new Draw('applet_qr', {height: 0, radius: [0, 0, 0, 0], width: 0}))
    }

    addAppletPoster(): void {
        this.data.draws = !this.data.draws ? [] : this.data.draws
        this.data.draws.push(new Draw('applet_poster', {version: 1}))
    }

    addThroughTrain(): void {
        this.data.draws = !this.data.draws ? [] : this.data.draws
        this.data.draws.push(new Draw('through_train', {version: 1}))
    }

    addGroupContentGroup(): void {
        this.data.draws = !this.data.draws ? [] : this.data.draws
        this.data.draws.push(new Draw('group_content_group', {color: 'ffffff'}))
    }

    validate(): Error | null {
        switch (true) {
            case this.data.image_url == '':
                return new Error('效果图没上传')
            case this.data.draws == null && this.data.template_image_url == '' :
                return new Error('底图没上传')
        }
        return null
    }
}

export const imageTemplate = new ImageTemplate({
    draws: null,
    image_template_id: "",
    image_url: "",
    template_image_url: null
})